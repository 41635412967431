<template>
  <main class="home-page">
    <HeroSection
      :isAuth="isAuth"
      @openSignUp="$emit('openSignUp')"
    />

    <HowItWorks />

    <ProductCarousel
      title="Most popular"
      apiEndpoint="items/list"
      showAllLink="/products/all"
      @goToProduct="goToProduct"
    />


    <StartShopping 
      :isAuth="isAuth" 
      @openSignUp="$emit('openSignUp')"
       />

    <!-- <section
      class="section support-page"
      v-if="popularFaq && popularFaq.length"
    >
      <div class="wrapper">
        <FaqSection
          :faq="popularFaq"
          title="Popular Questions"
        />
      </div>
    </section> -->

  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import HeroSection from '@/components/HeroSection.vue'
// import StatSection from "@/components/StatSection.vue"
import HowItWorks from "@/components/HowItWorks.vue"
import StartShopping from "@/components/StartShopping.vue"
// import FaqSection from "@/components/FaqSection.vue"
import ProductCarousel from '@/components/ProductCarousel.vue'

export default {
  name: 'HomePage',
  components: {
    HeroSection,
    // StatSection,
    HowItWorks,
    StartShopping,
    // FaqSection,
    ProductCarousel
  },
  props: {
    currencySymbol: {
      type: String,
      required: true
    },
    isAuth: {
      type: Boolean,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
  },
  data: function () {
    return {
      // popularFaq: []
    }
  },
  computed: {
    ...mapGetters('app', ['currentLanguage']),
  },
  async mounted() {
    // this.getFaq()
    if (this.isAuth) {
      // await this.fetchActiveDiscount(this.currencyCode)
      // if (this.hasActiveDiscount) {
      //   this.openDiscountModal()
      // }
    }
  },
  methods: {
    // ...mapActions('discount', ['fetchActiveDiscount']),
    // getFaq() {
    //   this.$http.get(`${process.env.VUE_APP_API}faqs/popular?lang_id=${this.currentLanguage}`)
    //     .then((res) => {
    //       this.popularFaq = res.data.payload
    //     })
    //     .catch(() => {
    //       // Handle error
    //     })
    // },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
  }
}
</script>

<style scoped></style>