<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="column logo-column">
        <router-link
          to="/"
          class="logo"
        >
          <img
            src="./../assets/img/logo.svg"
            class="img"
            alt="Logo"
          >
        </router-link>
        <div class="company-info">
          {{ footerRequisites }}
        </div>
      </div>
      <div class="column">
        <ul class="nav">
          <li class="nav__item">
            <router-link
              class="desc"
              to="/products/all"
            >
              <b>{{ $t('Market') }}</b>
            </router-link>
          </li>
          <li
            v-for="item in textPageList"
            :key="item.id"
            class="nav__item"
          >
            <a
              v-if="item"
              @click="goToPage(item.id)"
              class="desc"
            >
              <b v-if="item.title">{{ $t(item.title) }}</b>
            </a>
          </li>
        </ul>
      </div>
      <div class="column support-column">
        <div class="support-content">
          <div class="desc support-title">
            <b>{{ $t('Support:') }}</b>
          </div>
          <div class="desc support-email">
            {{ footerSupportEmail }}
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper payment-and-social">
      <div
        v-if="paymentMethodsList && paymentMethodsList.length"
        class="payment-methods"
      >
        <ul class="payment-methods-list">
          <li
            v-for="image in allImages"
            :key="image"
            class="image-wrapper"
          >
            <img
              :src="image"
              class="support-icon img"
              alt="Payment method"
            >
          </li>
        </ul>
      </div>
      <div class="social-links">
        <a
          v-for="link in socials"
          :key="link.image"
          :href="link.link"
          target="_blank"
          class="item"
        >
          <img
            :src="link.img"
            class="img"
            alt="Social link"
          >
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
    textPageList: {
      type: Array,
      required: true
    },
    footerRequisites: {
      type: String,
      required: true
    },
    footerSupportEmail: {
      type: String,
      required: true
    },
    paymentMethods: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      socials: [],
    }
  },
  computed: {
    paymentMethodsList() {
      return this.paymentMethods || [];
    },
    allImages() {
      return this.paymentMethodsList.flatMap(this.getImages);
    }
  },
  mounted() {
    this.getSocials();
  },
  methods: {
    getImages(method) {
      const images = [];
      if (method.code === 'card' || method.title === 'Visa/Mastercard') {
        images.push(require('@/assets/img/methods/visa.svg'), require('@/assets/img/methods/mc_symbol.svg'));
        // images.push(require('@/assets/img/methods/3ds.png'));
      } else if (method.code === 'eps' || method.title === 'EPS') {
        images.push(require('@/assets/img/methods/eps.svg'));
      } else if (method.code === 'trustly' || method.title === 'Trustly') {
        images.push(require('@/assets/img/methods/trustly.svg'));
      } else if (method.code === 'giropay' || method.title === 'GiroPay') {
        images.push(require('@/assets/img/methods/giropay.svg'));
      } else if (method.code === 'blik' || method.title === 'Blik') {
        images.push(require('@/assets/img/methods/blik.svg'));
      } else if (method.code === 'paidby' || method.title === 'Paidby') {
        images.push(require('@/assets/img/methods/paidby.svg'));
      } else if (method.code === 'klarna' || method.title === 'Klarna') {
        images.push(require('@/assets/img/methods/klarna.svg'));
      } else if (method.code === 'sofortuberweisung' || method.title === 'Sofortuberweisung') {
        images.push(require('@/assets/img/methods/sofortuber.svg'));
      } else if (method.code === 'ideal' || method.title === 'iDEAL') {
        images.push(require('@/assets/img/methods/ideal.svg'));
      } else if (method.code === 'bancontact' || method.title === 'Bancontact') {
        images.push(require('@/assets/img/methods/bancontact.svg'));
      }
      return images;
    },
    goToPage(id) {
      this.$emit('goToPage', id)
    },
    getSocials() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
        .then((res) => {
          this.socials = res.data.payload;
        })
        .catch(() => {
          // Handle error
        });
    },
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding: 64px 5px 24px;
  background: #FFFFFF0D;
}

.footer .wrapper {
  max-width: 1142px;
  margin: 0 auto;
  display: flex;
  gap: 32px;
  justify-content: flex-start;
}

.footer .wrapper .column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer .wrapper .column.logo-column {
  justify-content: space-between;
  gap: 24px;
}

.footer .wrapper .column.support-column {
  justify-content: flex-end;
}

.footer .wrapper .column .logo .img {
  width: 129px;
  height: 29px;
}

.footer .wrapper .column .company-info {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #b8b8c0;
}

.footer .wrapper .column .nav {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer .wrapper .column .nav .nav__item .desc {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: white;
  text-decoration: none;
}

.footer .wrapper .column .support-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer .wrapper .column .support-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: white;
}

.footer .wrapper .column .support-email {
  color: #00B230;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.footer .wrapper.payment-and-social {
  margin-top: 24px;
  justify-content: space-between;
}

.footer .payment-methods-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.footer .payment-methods-list img {
  max-width: 70px;
  max-height: 35px;
  display: inline-block;
  width: 100%;
}

.footer .social-links {
  display: flex;
  gap: 16px;
}

.footer .social-links .img {
  width: 24px;
}

@media (max-width: 768px) {
  .footer .wrapper {
    flex-direction: column;
  }

  .footer {
    padding: 32px 16px;
  }

  .footer .wrapper .column.logo-column,
  .footer .wrapper .column.support-column {
    justify-content: flex-start;
  }

  .footer .wrapper .column .company-info {
    margin-top: 24px;
  }

  .footer .wrapper .column .support-content {
    margin-top: 24px;
  }
}
</style>