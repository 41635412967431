<template>
    <section class="how-it-works-section section">
        <div class="wrapper">
            <img
                src="@/assets/img/01.png"
                class="how-it-work-image how-01"
                alt="01"
            >
            <img
                src="@/assets/img/02.png"
                class="how-it-work-image how-02"
                alt="02"
            >
            <img
                src="@/assets/img/03.png"
                class="how-it-work-image how-03"
                alt="03"
            >
            <h2 class="section-title">{{ $t(`How it works:`) }}</h2>
            <div class="cards-container">
                <!-- First Card -->
                <div class="card">
                    <h3 class="card-title">{{ $t(`Browse the Collection`) }}</h3>
                    <p class="card-description">{{ $t(`Search through our vast range of CS2 skins, categorized by
                        rarity, weapon type, or style.`) }}</p>
                </div>

                <!-- Second Card -->
                <div class="card">
                    <h3 class="card-title">{{ $t(`Choose Your Skin`) }}</h3>
                    <p class="card-description">{{ $t(`Once you've found the perfect skin, add it to your cart and
                        proceed to checkout.`) }}</p>
                </div>

                <!-- Third Card -->
                <div class="card">
                    <h3 class="card-title">{{ $t(`Equip Your New Skins`) }}</h3>
                    <p class="card-description">{{ $t(`Receive your skins instantly and equip them in-game to showcase
                        your unique style.`) }}</p>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: 'HowItWorks',
    data() {
        return {
        }
    }
}
</script>


<style lang="scss" scoped>
.section {}

.section .wrapper {
    position: relative;
}

.section .how-it-work-image {
    position: absolute;
    z-index: 0;
    transition: all 0.3s ease;
}

.section .how-01 {
    top: 63px;
    left: -34px;
}

.section .how-02 {
    bottom: 76px;
    left: 50%;
    transform: translateX(-50%);
}

.section .how-03 {
    top: 63px;
    right: -32px;
}

.section .section-title {
    font-size: 52px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: white;
    margin-bottom: 51px;
}

.section .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 37px;
}

.section .card {
    width: 402px;
    height: 252px;
    border-radius: 12px;
    padding: 61px 44px 44px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(0, 178, 48, 0.1);
    backdrop-filter: blur(12px);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;

    &::before {
        content: '';
        position: absolute;
        inset: -1px;
        z-index: -1;
        padding: 2px;
        border-radius: 14px;
        background-image: linear-gradient(180deg, #FFE644 0%, #00B230 100%);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
    }
}

.section .card .card-title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: white;
    margin-bottom: 28px;
}

.section .card .card-description {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #B8B8C0;
}

@media (max-width: 1200px) {
    .section .how-01 {
        left: -20px;
    }

    .section .how-03 {
        right: -20px;
    }
}

@media (max-width: 992px) {
    .section .how-01 {
        left: 0;
    }

    .section .how-03 {
        right: 0;
    }
}

@media (max-width: 768px) {
    .section .cards-container {
        flex-direction: column;
        align-items: center;
    }

    .section .card {
        width: 100%;
        max-width: 402px;
    }

    .section .how-it-work-image {
        display: none;
    }
}

@media (max-width: 480px) {}
</style>